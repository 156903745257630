import { ResultOf } from "@internal/gql/client";
import { Primary, Secondary } from "@internal/gql/generated/types";
import { exhaustiveCheck } from "@internal/utils/types";
import { isDraft } from "screens/deals/utils";
import { formatIsoDate } from "utility/dates";

import { createdDealsQuery } from "./useCreatedDeals";

export type CreatedDeals = ResultOf<typeof createdDealsQuery>["createdDeals"];

export function sortCreatedDeals(deals: CreatedDeals | undefined) {
  if (!deals) return [];

  const live = deals.filter((deal) => deal.status === "LIVE");
  const closing1 = deals.filter((deal) => deal.status === "CALCULATING_FEES");
  const closing2 = deals.filter((deal) => deal.status === "AWAITING_LEGALS");
  const closing3 = deals.filter((deal) => deal.status === "REVIEWING_LEGALS");
  const closing4 = deals.filter((deal) => deal.status === "WIRING_FUNDS");
  const review = deals.filter((deal) => deal.status === "REVIEW");
  const draft = deals.filter((deal) => deal.status === "DRAFT");

  return [
    ...live,
    ...closing1,
    ...closing2,
    ...closing3,
    ...closing4,
    ...review,
    ...draft,
  ];
}

export function formatDealType(dealType: (Primary | Secondary)["__typename"]) {
  switch (dealType) {
    case "Primary":
      return "Primary";
    case "Secondary":
      return "Secondary";
    default:
      return exhaustiveCheck(dealType, {
        fallback: "Unknown",
      });
  }
}

export function prefillDraftWithoutNameOrAmount(deal: CreatedDeals[0]) {
  if (isDraft(deal.status)) {
    return {
      ...deal,
      // when filtering the table by `Amount received`, make sure drafts are at the bottom
      // when the sort order is ascending.
      investmentSummary: {
        __typename: "InvestmentSummary",
        investorsInvested: 0,
        investorsTotal: 0,
        totalReceived: {
          __typename: "MonetaryValue",
          currency: "GBP",
          formatted: "0",
          amount: deal.status === "DRAFT" ? -2 : -1,
        },
      } as const,
      // allows the table filter on "Company" to work correctly alphabetically when drafts
      // do not have a name
      ...(!deal.tradeName
        ? {
            tradeName: `Draft ${formatIsoDate(deal.createdAt)}`,
          }
        : null),
    };
  }

  return deal;
}
